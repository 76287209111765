<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-card-account-details-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="9" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.nome"
                    label="Nome"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.cpf"
                    label="CPF"
                    v-mask="'###.###.###-##'"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.dni"
                    label="DNI"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.identidade"
                    label="Identidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.orgaoEmissor"
                    label="Órgão Emissor"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varMotoristaLocal.dataNascimento"
                    label="Nascimento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.cidadeNascimento"
                    label="Cidade Nascimento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.cnh"
                    label="CNH"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varMotoristaLocal.vencimentoCnh"
                    label="Vencimento CNH"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.nomeMae"
                    label="Nome da mãe"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.nomePai"
                    label="Nome do pai"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.cep"
                    label="CEP"
                    v-mask="'#####-###'"
                    outlined
                    @blur="metObterDadosCep(varMotoristaLocal.cep)"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="8" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.endereco"
                    label="Endereço"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.numero"
                    label="Número"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.complemento"
                    label="Complemento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.bairro"
                    label="Bairro"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="5" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.idPais"
                    label="País"
                    :items="compPaisesSelect"
                    item-text="nome_pt"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="5" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.cidade"
                    label="Cidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.email"
                    label="E-mail"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.telefone"
                    label="Telefone"
                    v-mask="metMaskFone(varMotoristaLocal.telefone)"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varMotoristaLocal.observacoes"
                    label="Observações"
                    rows="2"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import axios from "../_services/axios";

const { mapState, mapActions } = createNamespacedHelpers("cadMotoristas");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varMotoristaLocal: {
  //     nome_motorista: { required },
  //     email: { required, email },
  //     motorista: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadMotoristasForm",
    tituloForm: "",
    varMotoristaLocal: {},
  }),

  computed: {
    ...mapState(["staMotoristaSelect", "staPaisesSelect"]),

    compPaisesSelect() {
      return this.staPaisesSelect;
    },
  },

  watch: {
    /* staMotoristaSelect(motoristaNovo) {
      this.metSincronizar(motoristaNovo);
    }, */
  },

  created() {
    this.metSincronizar(this.staMotoristaSelect);
    if (this.staMotoristaSelect) {
      this.tituloForm = "Editar Motorista";
    } else {
      this.tituloForm = "Nova Motorista";
    }
    this.actPaisesSelect();
    this.metDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarMotorista",
      "actResetarMotorista",
      "actCriarMotorista",
      "actEditarMotorista",
      "actResetarErro",
      "actPaisesSelect",
    ]),

    async metObterDadosCep(cep) {
      if (cep) {
        try {
          var dadosCep = await axios.getObterDadosCep(cep);
          this.varMotoristaLocal.endereco =
            dadosCep.data.tipo_logradouro + " " + dadosCep.data.nome_logradouro;
          this.varMotoristaLocal.bairro = dadosCep.data.bairro;
          this.varMotoristaLocal.cidade = dadosCep.data.nome_localidade;
          this.varMotoristaLocal.uf = dadosCep.data.uf;
        } catch (err) {
          // console.log(err);
        }
      }
    },

    metMaskFone(fone) {
      if (fone == null) {
        return "";
      }
      if (fone.includes("(")) {
        return fone.length <= 13 ? "(##)####-####" : "(##)# ####-####";
      } else {
        return fone.length <= 10 ? "(##)####-####" : "(##)# ####-####";
      }
    },

    selecionarMotoristaEdicao(motorista) {
      this.actSelecionarMotorista({ motorista });
    },

    async salvarMotorista() {
      switch (this.staMotoristaSelect) {
        case undefined:
          this.varMotoristaLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varMotoristaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarMotorista({
            motorista: this.varMotoristaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          if (this.$store.state.cadMotoristas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadMotoristas.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Motorista";
            this.$root.snackbar.show({
              type: "success",
              message: "Motorista Criado!",
            });
          }
          break;
        default:
          this.varMotoristaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varMotoristaLocal.criado = undefined;
          this.varMotoristaLocal.criacao = undefined;
          this.varMotoristaLocal.modificado = undefined;
          await this.actEditarMotorista({
            motorista: this.varMotoristaLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadMotoristas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadMotoristas.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Motorista Salvo!",
            });
          }
      }
      //this.resetar();
    },

    metDatasDB() {
      this.varMotoristaLocal.dataNascimento = this.mixDataDBDCal(
        this.varMotoristaLocal.dataNascimento
      );
      this.varMotoristaLocal.vencimentoCnh = this.mixDataDBDCal(
        this.varMotoristaLocal.vencimentoCnh
      );
    },

    metSincronizar(motorista) {
      this.varMotoristaLocal = Object.assign(
        {},
        motorista || {
          id: null,
          endereco: null,
          bairro: null,
          cidade: null,
          uf: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarMotorista();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varMotoristaLocal.ultima_compra = this.staMotoristaSelect.ultima_compra;
      // this.varMotoristaLocal.email = this.staMotoristaSelect.email;
      // this.varMotoristaLocal.motorista = this.staMotoristaSelect.motorista;
      // this.varMotoristaLocal.senha = this.staMotoristaSelect.senha;
      // this.varMotoristaLocal.ativo = this.staMotoristaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
